import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef, type ReactNode } from 'react'

import Brand from '@/components/Brand'
import Menu from '@/components/Menu'
import useIntersection from '@/hooks/useIntersection'
import useSettings from '@/hooks/useSettings'
import { PortableText } from '@portabletext/react'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

const FULLSCREEN_PAGES = ['/quiz', '/presentasjon', '/samtalekort']
const SKIP_MENU = ['/elevopplegg']

interface Props {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  const router = useRouter()
  const footerRef = useRef<HTMLSpanElement>(null)
  const isVisible = useIntersection(footerRef)
  const settings = useSettings()

  const isFullscreen = FULLSCREEN_PAGES.some((slug) => router.pathname.startsWith(slug))
  const skipMenu = SKIP_MENU.some((slug) => router.pathname.startsWith(slug))

  return (
    <div {...bem('', { full: isFullscreen })}>
      {!isFullscreen && (
        <div {...bem('marg')}>
          {!skipMenu && <Menu footerVisible={isVisible} />}

          <span {...bem('logo')}>
            <Brand href="/" />
          </span>
        </div>
      )}

      <div {...bem('content')}>
        <main {...bem('page')}>{children}</main>
      </div>

      <footer
        id="footer"
        {...bem('footer', {
          show: isVisible && !isFullscreen,
          hidden: isFullscreen,
        })}
      >
        <h2 {...bem('what', { show: isVisible })}>
          {settings?.footer?.footerText && (
            <PortableText
              value={settings.footer?.footerText}
              //components={blockContentSerializers}
            />
          )}
        </h2>
        <span ref={footerRef} />

        <nav {...bem('links')}>
          {settings?.footer?.menu?.map((link) => {
            if (link.type === 'internalRoute') {
              return (
                <Link key={link._key} href={link.internalRoute} {...bem('link')}>
                  {link.title}
                </Link>
              )
            } else {
              return (
                <a
                  key={link._key}
                  href={`https://${link.externalRoute}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...bem('link')}
                >
                  {link.title}
                </a>
              )
            }
          }) ?? null}
        </nav>

        <p {...bem('tramp-stamp')}>
          Publisert med{' '}
          <a href="https://sanity.io" target="_blank" rel="noopener noreferrer">
            sanity.io
          </a>
        </p>
      </footer>
    </div>
  )
}
